import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, MenuItem, useMediaQuery, IconButton } from '@material-ui/core';
import { MdMenu } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { logout } from 'features/auth/authSlice';
import avatarIcon from '../../../assets/images/avatar_icon.png';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const UserMenu = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;

  :hover {
    cursor: pointer;
  }
`;

const StyledMenu = styled(Menu)`
  margin: 55px 0 0;
  .MuiPaper-root {
    padding: 0;
  }
`;

export default function TopMenu({ onToggleDrawer }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState();
  const currentUser = useSelector(state => state.auth.current);
  const isWideView = useMediaQuery('(min-width: 830px)');
  const history = useHistory();

  const handleClick = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () =>
    dispatch(
      logout({
        errorMessage: 'There was an error logging out',
        successMessage: 'Successfully logged out',
        onSuccess: () => history.push('/'),
      })
    );

  return (
    <Container>
      {!isWideView && (
        <IconButton onClick={onToggleDrawer} style={{ marginRight: '1.3rem' }}>
          <MdMenu />
        </IconButton>
      )}
      <UserMenu onClick={handleClick}>
        <img src={avatarIcon} style={{ width: 25 }} alt="User menu icon" />
        {isWideView && (
          <div style={{ marginLeft: 12 }}>{currentUser.group___name}</div>
        )}
      </UserMenu>
      <StyledMenu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={Link} to="/settings" onClick={handleClose}>
          {t`Settings`}
        </MenuItem>
        <MenuItem onClick={handleLogout}>{t`Logout`}</MenuItem>
      </StyledMenu>
    </Container>
  );
}
