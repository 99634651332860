import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Drawer, MenuItem } from '@material-ui/core';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Container, StyledTabs, StyledTab } from './Content/Content.styled';
import { useSelector } from 'react-redux';

const StyledMenuItem = styled(MenuItem)`
  text-transform: uppercase;
  padding: 1rem 2rem;

  ${props =>
    props.isSelected
      ? `
    background: #fff;
    color: #0E2B4D;
    &:hover {
      color: #7B7D81;
    }
  `
      : ''}
`;

export default function Menu({ isDrawerOpen, handleDrawerClose }) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [chosenTab, setChosenTab] = useState();
  const handleTabChange = (e, value) => history.push(value);
  const isWideView = useMediaQuery('(min-width: 830px)');

  useEffect(() => {
    setChosenTab(location.pathname.replace('/', ''));
  }, [location.pathname]);

  return isWideView ? (
    <Container>
      {isWideView && (
        <StyledTabs
          value={chosenTab}
          aria-label="Section navigation"
          onChange={handleTabChange}
        >
          <StyledTab label={t('Locations')} value="" index={0} />
          <StyledTab
            label={t('My deliveries')}
            value="my-deliveries"
            index={1}
          />
          <StyledTab label={t('QR Codes')} value="qr-codes" index={2} />
        </StyledTabs>
      )}
    </Container>
  ) : (
    <Drawer
      open={isDrawerOpen}
      onClose={handleDrawerClose}
      PaperProps={{ style: { border: 'none', width: 220 } }}
    >
      <div style={{ padding: '3rem 0' }}>
        <StyledMenuItem
          component={Link}
          to="/"
          onClick={handleDrawerClose}
          isSelected={location.pathname === '/'}
        >
          {t('Locations')}
        </StyledMenuItem>
        <StyledMenuItem
          component={Link}
          to="/my-deliveries"
          onClick={handleDrawerClose}
          isSelected={location.pathname === '/my-deliveries'}
        >
          {t('My deliveries')}
        </StyledMenuItem>
        <StyledMenuItem
          component={Link}
          to="/qr-codes"
          onClick={handleDrawerClose}
          isSelected={location.pathname === '/qr-codes'}
        >
          {t('QR Codes')}
        </StyledMenuItem>
      </div>
    </Drawer>
  );
}
