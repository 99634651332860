import Loader from 'components/shared/Loader';
import PropTypes from 'prop-types';
import React, { Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
import Content from './Content';
import Menu from './Menu';
import Topbar from './Topbar';
import UnverifiedUser from './UnverifiedUser';

const Layout = ({ children, page }) => {
  const requiresValidation = useSelector(
    state => state.auth.current.status === 'PENDING_VERIFICATION'
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState();
  const handleToggleDrawer = () => setIsDrawerOpen(prev => !prev);
  return (
    <>
      <Suspense
        fallback={
          <div style={{ margin: '3rem' }}>
            <Loader button />
          </div>
        }
      >
        {requiresValidation ? <UnverifiedUser /> : ''}
        <Topbar onToggleDrawer={handleToggleDrawer} />
        <Menu
          isDrawerOpen={isDrawerOpen}
          handleDrawerClose={handleToggleDrawer}
        />
      </Suspense>
      <Content page={page}>{children}</Content>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  goBack: PropTypes.func,
};

export default Layout;
