import { normalize } from 'normalizr';
import { toast } from 'react-toastify';
import httpClient from '../utilities/httpClient';

export const CALL_API = 'CALL_API';

function validateCallAPI(types, promise, schema) {
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }
  return true;
}

function determineErrorMessage(err) {
  if ((err.response && !err.response.status) || err.code === 'ECONNABORTED') {
    return 'Cannot connect to server. Please try again or contact support.';
  }
  // TODO: if there is a response code then do a look up for it
}

export default store => next => action => {
  const callApi = action[CALL_API];
  if (!callApi) return next(action);

  const {
    types,
    promiseFn,
    schema,
    successMessage,
    errorMessage,
    requestErrorMessage,
    unauthorizedMessage,
    onSuccess,
    onError,
  } = callApi;

  validateCallAPI(types, promiseFn, schema);

  const [requestType, successType, failureType] = types;
  next({ type: requestType, ...action });

  const actionPromise = promiseFn(httpClient);

  actionPromise
    .then(res => {
      if (successMessage) toast.success(successMessage);
      if (onSuccess) onSuccess(res.data);

      const data = schema ? normalize(res.data, schema) : res.data;

      const nextAction = {
        type: successType,
        payload: data,
        ...action,
      };

      return next(nextAction);
    })
    .catch(err => {
      if (err.response && err.response.status === 401) {
        if (unauthorizedMessage) {
          toast.error(unauthorizedMessage);
        } else {
          toast.warning('You have been automatically logged out');
        }
        return next({
          type: 'auth/checkAuth/rejected',
        });
      }
      if (requestErrorMessage && err?.response?.status === 400) {
        toast.error(requestErrorMessage);
      } else if (errorMessage) {
        toast.error(errorMessage);
      } else if (err.code === 'ECONNABORTED') {
        toast.error('The request timed out.');
      }
      if (onError) onError();

      const nextAction = {
        type: failureType,
        payload: err,
        errorMessage: determineErrorMessage(err),
        ...action,
      };

      return next(nextAction);
    });
};
