import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const Container = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 1280px;
  position: relative;
  .support {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 1rem auto;
    a.contact {
      img {
        margin-right: 0.3rem;
      }
      display: flex;
      align-items: center;
      margin-left: 1rem;
      font-weight: 600;
    }
  }
  .version {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.5;
  }
`;

export const StyledTabs = styled(Tabs)`
  margin: 10px 0 -5px;
`;

export const StyledTab = styled(Tab)`
  &:not(:first-child) {
    margin-left: -16px;
  }
  z-index: ${props => 9 - props.index};
`;

export const PageContent = styled(Paper)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${props => (props.isWideView ? '0 11px 11px 11px' : '11px')};
  z-index: 99;
  position: relative;
  padding: 18px 36px 36px;
  margin-bottom: 3rem;
`;
