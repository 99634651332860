import smartboxbaselTheme from './themes/smartboxbaselTheme';
import smartboxbaselImages from './assets/images/brands/smartboxbasel';

import flexicplTheme from './themes/flexicplTheme';
import flexicplImages from './assets/images/brands/flexicpl';

import schlaueboxTheme from './themes/schlaueboxTheme';
import schlaueboxImages from './assets/images/brands/schlauebox';

export const brandList = {
  SMARTBOXBASEL: 'smartboxbasel',
  FLEXICPL: 'flexicpl',
  JELMOLI: 'jelmoli',
  SCHLAUEBOX: 'schlauebox',
};

export default {
  smartboxbasel: {
    name: 'SmartBoxBasel',
    theme: smartboxbaselTheme,
    androidUrl:
      'https://play.google.com/store/apps/details?id=hr.sil.android.cpluser&hl=en',
    iosUrl: 'https://apps.apple.com/us/app/id1533947736',
    ...smartboxbaselImages,
  },
  flexicpl: {
    name: 'Flexilocker CPL',
    theme: flexicplTheme,
    androidUrl:
      'https://play.google.com/store/apps/details?id=hr.sil.android.cplflexilockeruser',
    iosUrl: 'https://apps.apple.com/us/app/flexilocker-cpl/id1525369183',
    ...flexicplImages,
  },
  jelmoli: {
    name: 'Jelmoli',
    theme: flexicplTheme,
    androidUrl:
      'https://play.google.com/store/apps/details?id=hr.sil.android.cplflexilockeruser',
    iosUrl: 'https://apps.apple.com/us/app/flexilocker-cpl/id1525369183',
    ...flexicplImages,
  },
  schlauebox: {
    name: 'Schlauebox',
    theme: schlaueboxTheme,
    androidUrl:
      'https://play.google.com/store/apps/details?id=hr.sil.android.cplflexilockeruser',
    iosUrl: 'https://apps.apple.com/us/app/flexilocker-cpl/id1525369183',
    ...schlaueboxImages,
  },
};
