import { Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import 'assets/css/material-ui-custom.css';
import 'assets/css/react-toastify-custom.css';
import brands from 'brands';
import { checkAuth } from 'features/auth/authSlice';
import { fetchLanguages } from 'features/languages/languagesSlice';
import 'normalize.css';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import i18n from './i18n';
import { Routes } from './Routes';

const { theme } = brands[process.env.REACT_APP_PROJECT];

const ToastClose = ({ closeToast }) => (
  <Button
    onClick={closeToast}
    style={{
      fontSize: '1.8rem',
      width: '2rem',
      color: 'rgba(255,255,255, 0.7)',
      marginTop: 8,
    }}
  >
    <MdClose />
  </Button>
);
ToastClose.propTypes = {
  closeToast: PropTypes.func,
};

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth('You have been automatically logged out'));
    dispatch(fetchLanguages());

    // set brand title
    document.title = `${brands[process.env.REACT_APP_PROJECT].name} Portal`;

    // set brand favicon
    const link =
      document.querySelector('link[rel*="icon"]') ||
      document.createElement('link');
    link.rel = 'shortcut icon';
    link.href = brands[process.env.REACT_APP_PROJECT].favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }, [dispatch]);

  useEffect(() => {
    const browserLang = navigator.language?.slice(0, 2);
    i18n.changeLanguage(browserLang);
  }, []);

  return (
    <StylesProvider injectFirst>
      {/* injectFirst prop allows styled components to inject after (override) MUI styles */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer position="bottom-right" closeButton={<ToastClose />} />
        <Routes />
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
