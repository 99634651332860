import React from 'react';
import { Link } from 'react-router-dom';
import brands from 'brands';
import TopMenu from './TopMenu';

import { Container } from './Topbar.styled';

const { logoLogin } = brands[process.env.REACT_APP_PROJECT];

const Topbar = ({ withMenu = true, onToggleDrawer }) => (
  <Container>
    <Link to="/">
      <img src={logoLogin} style={{ width: 230 }} />
    </Link>
    {withMenu && <TopMenu onToggleDrawer={onToggleDrawer} />}
  </Container>
);

export default Topbar;
