import { Link, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import emailIcon from '../../../assets/images/ic_email.svg';
import phoneIcon from '../../../assets/images/ic_phone.svg';
import consts from '../../../constants';
import { Container, PageContent } from './Content.styled';

const project = process.env.REACT_APP_PROJECT;

const Topbar = ({ children }) => {
  const isWideView = useMediaQuery('(min-width: 830px)');
  const { t } = useTranslation();

  return (
    <Container>
      <PageContent isWideView={isWideView}>{children}</PageContent>
      <div className="support">
        {project === 'smartboxbasel' ? (
          <>
            {t`Support`} [{t`workdays`} 8-12 & 14-17h]:
            <Link href="tel:0647859085" className="contact">
              <img src={phoneIcon} />
              064 785 90 85
            </Link>
            <Link href="mailto:support@smartboxbasel.ch" className="contact">
              <img src={emailIcon} />
              support@smartboxbasel.ch
            </Link>
          </>
        ) : null}
        <div className="version">v{consts.VERSION}</div>
      </div>
    </Container>
  );
};

export default Topbar;
